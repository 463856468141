
import {computed, defineComponent, ref} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {DOCUMENT_TYPES} from "@/core/config/Constant";
import {Field} from "vee-validate";
import MailService from "@/core/services/MailService";

export default defineComponent({
  name: "FileAssignForm",
  components: {BaseSelect, BaseRadio, BaseForm, Field},
  props: {
    mailId: {},
    fileId: {},
    data: {},
    options: {},
  },
  emits: ['saved'],
  setup(props) {
    const model = computed<any>(() => props.data);
    const submitting = ref(false);
    return {
      model,
      submitting,
      DOCUMENT_TYPES
    };
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      MailService.assignFile(this.mailId, this.fileId, this.model).then(res=>{
        this.$emit('saved', res);
      }).finally(()=>{
        this.submitting = false;
      })
    }
  }
})
