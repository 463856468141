import {BaseService} from "@/core/services/BaseService";

class MailService extends BaseService<any> {
  endpoint = "/mail"

  async mark(id) {
    return this.http().get(this.endpoint + "/" + id + "/mark").then(res => {
      return res.data;
    });
  }

  async archive(id: any) {
    return this.http().get(this.endpoint + "/" + id + "/archive").then(res => {
      return res.data;
    });
  }

  async attachments(id: any) {
    return this.http().get(this.endpoint + "/" + id + "/attachments").then(res => {
      return res.data;
    });
  }

  async assign(id, data) {
    return this.http().post(this.endpoint + "/" + id + "/assign", data).then(res => {
      return res.data;
    });
  }

  async assignFile(id, docId, data) {
    return this.http().post(this.endpoint + "/" + id + "/attachments/" + docId, data).then(res => {
      return res.data;
    });
  }

  async conversations(id: any) {
    return this.http().get<any>(this.endpoint + "/" + id + "/conversations").then((res: any) => {
      return res.data;
    })
  }
}

export default new MailService();