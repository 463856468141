<script setup>
import {computed, defineProps} from "vue";

// Props
const props = defineProps({
  date: {
    type: [String, Date],
    required: true,
  },
});

// Convert to Date object
const dateValue = computed(() => new Date(props.date));

// Function to get relative time
const getRelativeTime = (date) => {
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  if (diffInSeconds < 60) {
    return rtf.format(-diffInSeconds, "second");
  }
  if (diffInSeconds < 3600) {
    return rtf.format(-Math.floor(diffInSeconds / 60), "minute");
  }
  if (diffInSeconds < 86400) {
    return rtf.format(-Math.floor(diffInSeconds / 3600), "hour");
  }

  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(date);
};

// Computed property for display
const formattedDate = computed(() => getRelativeTime(dateValue.value));
</script>

<template>
  <span>{{ formattedDate }}</span>
</template>
