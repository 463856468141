
import {defineComponent, ref, watch} from 'vue'
import MailService from "@/core/services/MailService";
import RelativeTime from "@/components/base/datetime/RelativeTime.vue";

export default defineComponent({
  name: "Conversations",
  components: {RelativeTime},
  props: {
    id: {}
  },
  setup(props) {
    const conversations = ref<any>([]);
    const loading = ref(true);
    const load = (id) => {
      loading.value = true;
      MailService.conversations(id).then(res => {
        conversations.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    load(props.id)
    watch(() => props.id, cb => {
      load(cb)
    })
    return {
      loading,
      conversations
    }

  }
})
