
import {computed, defineComponent, ref} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseMultiSelect from "@/components/base/select/BaseMultiSelect.vue";
import {CaseSelectFilter, ClaimSelectFilter, PolicySelect} from "@/core/composite/composite";
import MailService from "@/core/services/MailService";

export default defineComponent({
  name: "MailAssignForm",
  components: {BaseMultiSelect, BaseForm},
  props: {
    id: {type: String, required: true},
    state: {},
  },
  emits: ['saved'],
  setup(props) {
    const submitting = ref(false);
    const model = computed(() => props.state)
    return {
      submitting,
      model,
      ...PolicySelect(),
      ...CaseSelectFilter({clientId: ''}, false),
      ...ClaimSelectFilter({clientId: ''}, false),
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      MailService.assign(this.id, this.model).then(res => {
        this.$emit('saved', res)
      }).finally(() => {
        this.submitting = false;
      })
    },
    onPolicySearch(event) {
      this.policyFilter.number = event
      this.loadPolicy();
    },
    onSearchCase(event) {
      this.caseFilter.number = event
      this.loadCaseSelectFilter();
    },
    onSearchClaim(event) {
      this.claimFilter.number = event;
      this.loadClaimSelectFilter();
    },
  }
})
