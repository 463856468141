
import {defineComponent, ref, watch} from 'vue'
import MailService from "@/core/services/MailService";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {LoadPanel} from "@/core/composite/composite";
import Swal from "sweetalert2";
import DocumentService from "@/core/services/DocumentService";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import FileAssignForm from "@/views/mail/FileAssignForm.vue";

export default defineComponent({
  name: "MailAttachments",
  components: {FileAssignForm, BaseModal, QuickAction},
  props: {
    mail: {type: Object, required: true},
  },
  setup(props) {
    const data = ref<any>([]);

    const load = (id) => {
      MailService.attachments(id).then(res => {
        data.value = res;
      })
    }

    load(props.mail.id)
    const state = ref<any>({options: [], model: {name: '', documentType: '', area:'GENERIC'}, docId: ''})
    watch(() => props.mail.id, cb => {
      load(cb)
    })

    return {
      data,
      state,
      ...LoadPanel(),
      load,
    }
  },
  methods: {
    onDownloadPdf(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      DocumentService.download(id).then(res => {
        let fileName = res.headers["content-disposition"].split("filename=")[1];
        let blob = new Blob(
          [res.data]
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).finally(() => {
        Swal.close()
      })
    },
    onAssign(doc) {
      this.state.options = []
      if (this.mail?.caze?.id) {
        this.state.options.push({value: 'CASE', name:`Case# ` + this.mail?.caze.number})
      }
      if (this.mail?.claim?.id) {
        this.state.options.push({value: 'CLAIM', name: `Claim#` + this.mail?.claim.number})
      }

      this.state.model.name = doc.name;
      this.state.model.objectType = '';
      this.state.model.documentType = doc.documentType.code;
      this.state.docId = doc.id
      this.state.model.area = doc.area
      const modal = this.$refs.formAssignFileRef as typeof BaseModal;
      modal.showBaseModal();
    },
    onSaved() {
      this.load(this.mail.id)
      const modal = this.$refs.formAssignFileRef as typeof BaseModal;
      modal.hideBaseModal();
    }
  }
})
