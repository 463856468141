
import {defineComponent, onMounted, ref, watch} from 'vue'
import MailService from "@/core/services/MailService";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import TagEntities from "@/views/tag/TagEntities.vue";
import {LoadPanel, LoadTagModal} from "@/core/composite/composite";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import MailAttachments from "@/views/mail/MailAttachments.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import MailAssignForm from "@/views/mail/MailAssignForm.vue";
import Row from "@/components/base/common/Row.vue";
import Conversations from "@/views/mail/Conversations.vue";

export default defineComponent({
  name: "MailDetail",
  components: {
    Conversations,
    Row, MailAssignForm, BaseModal, MailAttachments, EntityLoading, TagEntities, DateTimeFormat
  },
  props: {
    mailId: {}
  },
  setup(props) {

    const state = ref({objectType: 'POLICY', objectId: ''})
    const data = ref<any>({});
    const loading = ref(false)
    const load = (id) => {
      loading.value = true;
      MailService.get(id).then(res => {
        data.value = res

      }).finally(() => {
        loading.value = false;
      })
    }
    load(props.mailId);

    watch(() => props.mailId, cb => {
      load(cb)
    })

    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Mail Details', [
        {link: false, router: '', text: 'Mail Details'}
      ])
    })

    return {
      data,
      loading,
      state,
      ...LoadTagModal(),
      ...LoadPanel(),
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onMark() {
      MailService.mark(this.data.id).then(res => {
        this.data.marked = res.marked
      })
    },
    onArchive() {
      MailService.archive(this.data.id).then(res => {
        this.data.archived = res.archived
      })
    },
    onAssign(type) {
      this.state.objectType = type;
      this.state.objectId = ''
      const model = this.$refs.assignCaseRef as typeof BaseModal;
      model.showBaseModal();
    },
    onAssigned(res) {
      this.data = res;
      const model = this.$refs.assignCaseRef as typeof BaseModal;
      model.hideBaseModal();
    }
  }
})
